import React, { useState } from 'react'
import cx from 'classnames'
import VisibilitySensor from 'react-visibility-sensor'

import jump from 'jump.js'
import BlockContent from '@sanity/block-content-to-react'

import { Serializer } from 'src/utils/serializer'


export interface FaqsProps {
  data: {
    faqs: any[]
  }
}

function slugify(text) {
  return text.toString().toLowerCase()
    .replace(/\s+/g, '-')           // Replace spaces with -
    .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
    .replace(/\-\-+/g, '-')         // Replace multiple - with single -
    .replace(/^-+/, '')             // Trim - from start of text
    .replace(/-+$/, '');            // Trim - from end of text
}

export const Faqs = ({ data }: FaqsProps) => {
  const {
    faqs
  } = data

  const [active, setActive] = useState('00')
  const [inView, setInView] = useState(slugify(faqs[0].title))

  const onChange = (isVisible, slug) => {
    if (isVisible) {
      setInView(slug)
    }
  }

  return (
    <div className={cx('x bb color--black')}>
      <div className='row product__grid gutter--none df fw jcb'>
        <div className='col bg--off-white c16 c3--800'>
          <div className='p2 faq__nav-bar faq__nav psy top p3--800 pt6--800'>
            <div className=''>
              <div className='show-800'>
                {faqs.map((faq, i) => (
                  <div className='bb' key={i}>
                    <button onClick={() => jump(`#${slugify(faq.title)}`, {
                      offset: -90
                    })} className={cx('db bg--off-white faq__button faq__nav s14 p0 pb1 pt1 m0 bn courier', {
                      'active': inView === slugify(faq.title)
                    })}>
                      {faq.title}
                    </button>
                  </div>
                ))}
              </div>
              <div>
                <p>
                  Don't see the answer you're looking for? Reach out to <a href='mailto:hello@eatocco.com'>hello@eatocco.com</a> and we'll get back to you in a flash.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='x col c16 c11--800 bl--800 bg--off-white'>
          <div className='p2 p3--800 pt6--800'>
            <div className='x container--1000 mxa'>
              {faqs.map((faq, i) => (
                <VisibilitySensor minTopValue='300' key={i} onChange={(isVisible) => onChange(isVisible, slugify(faq.title))}>
                  <div className='mb8'>
                    <h3 className='bb pb2' id={slugify(faq.title)}>{faq.title}</h3>
                    <div className='faqs__inner'>
                      {faq.faqs.map((single, fi) => (
                        <div key={fi} className={cx('bb faqs__single', {
                          'is-open': active === `${i}${fi}`
                          })}>
                          <button onClick={() => active === `${i}${fi}` ? setActive('') : setActive(`${i}${fi}`)} className='faq__button bg-transparent df jcb aic bn pl0 pr0 pt1 pb1 pt2--600 pb2--600 s24 founders'>
                            <span className='faq__question'>{single.question}</span>
                            <div className='faq__circle df pr jcc aic'>
                              <svg className='di pa' width="14" height="14" viewBox="0 0 14 14" fill="none">
                                <path d="M7.00281 12.7018L7.00281 1.29834" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M1.29962 7.00012L12.7031 7.00012" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                            </div>
                          </button>
                          <div className='faq__answer'>
                            <div className='pb2'>
                              <BlockContent blocks={single.text} serializers={Serializer} />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </VisibilitySensor>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Faqs